
.live-script {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 20px 24px;
  overflow-y: auto;
  .head-box {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #dcdcdc;
    .title {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .add-btn {
    padding: 16px 0;
  }
  .script-item {
    margin-bottom: 12px;
    .script-item-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
  }
  .goods-info {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
    }
  }
  .good-item-box {
    padding: 16px;
    background: #f6f6f6;
    margin-bottom: 12px;
    display: flex;
    position: relative;
    .good-text {
      width: 360px;
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .icon {
      position: absolute;
      bottom: -4px;
      left: 50%;
      margin-left: -4px;
      cursor: pointer;
    }
    .expand-icon {
      border-top: 8px solid #333;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
    .pack-icon {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #333;
      margin-left: -12px;
    }
  }
  .singe-line {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
  }
}
